import "@hotwired/turbo-rails";
import { Application } from "@hotwired/stimulus";
import AudioPlayerController from "../controllers/audio_player_controller";
import CarouselController from "../controllers/carousel_controller";
import CircleController from "../controllers/circle_controller";
import FormController from "../controllers/form_controller";
import FormMultipleController from "../controllers/form_multiple_controller";
import MixpanelController from "../controllers/mixpanel_controller";
import ProviderController from "../controllers/provider_controller";
import SignupController from "../controllers/signup_controller";

const application = Application.start();

application.register("audio-player", AudioPlayerController);
application.register("carousel", CarouselController);
application.register("circle", CircleController);
application.register("form", FormController);
application.register("form-multiple", FormMultipleController);
application.register("mixpanel", MixpanelController);
application.register("provider", ProviderController);
application.register("signup", SignupController);
